import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import SummaryComponent from '../components/SummaryComponent';

const SummaryPage = () => (
  <Layout pageTitle={'Summary'} hideFooter>
    <SEO title={'Summary'} />
    <SummaryComponent />
  </Layout>

)

export default SummaryPage;