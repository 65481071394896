import React, { useState, useEffect } from 'react';
import Clipboard from 'react-clipboard.js';
import './SummaryComponent.scss';

export default function SummaryComponent({}) {
  const [text, setText] = useState(undefined);
  const getSummary = async (uid) => {
    const spendingContainer = document.getElementById('spending-container-response');
    const accountsContainer = document.getElementById('accounts-container-response');

    spendingContainer.innerHTML = '';
    accountsContainer.innerHTML = '';

    const summary = await (
      await fetch(`https://prod.api.mosaicmoney.app/misc/summary?uid=${uid}`)
    ).json();

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    var clipboardText = '';

    const spendingValueElem = document.createElement('p');
    const spendingValue = formatter.format(summary.spending);
    spendingValueElem.textContent = spendingValue;
    spendingContainer.appendChild(spendingValueElem);
    clipboardText += `Spending: ${spendingValue}\n`;

    const accountsElems = Object.keys(summary.accounts).map(accountKey => {
      const elem = document.createElement('p');
      const accountType = `${accountKey.charAt(0).toUpperCase() +
        accountKey.slice(1)}`;
      const accountValue = `${formatter.format(summary.accounts[accountKey])}`;
      const text = `\n${accountType}: ${accountValue}`;
      elem.textContent = text;
      clipboardText += text;
      return elem;
    });

    accountsContainer.append(...accountsElems);
    setText(clipboardText);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('uid');
    if (uid) {
      getSummary(uid); 
    } else {
      alert('Invalid url');
    }
  });

  return (
    <section style={{visibility: text ? 'visible' : 'hidden'}} className="summary-component-section">
      <p style={{visibility: text ? 'hidden' : 'visible'}}>Loading...</p>
      <div id="spending-container">
        <h2>Spending</h2>
        <div id="spending-container-response"></div>
      </div>
      <div id="accounts-container">
        <h2>Accounts</h2>
        <div id="accounts-container-response"></div>
      </div>
      <Clipboard className="clipboard-button" data-clipboard-text={text}>Copy to Clipboard</Clipboard>
    </section>
  );
}
